// CSS
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "react-phone-input-2/lib/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.css";

// Other packeges components
import moment from "moment-timezone";
import { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { io } from "socket.io-client";

// configration variables
import SuspenceLoader from "./Component/SuspenceLoader";
import { APP_VERSION, BASE_URL } from "./Configration/configration";

// Pages components
// import ForgetPassword from "./Pages/ForgetPassword";
// import Home from "./Pages/Home";
// import Login from "./Pages/Login";
// import PrivacyPolicy from "./Pages/PrivacyPolicy";
// import ProviderDetailsPage from "./Pages/ProviderDetailsPage";
// import TermsAndCondition from "./Pages/TermsAndCondition";
// import UserProfile from "./Pages/UserProfile";
// import ComingSoon from "./Component/ComingSoon";
// import CreateEvent from "./Component/CreateEvent/CreateEvent";
// import EventBooking from "./Component/EventBooking";
// import EventInfo from "./Component/EventInfo/EventInfo";
// import ModifyEventBooking from "./Component/ModifyEventBooking";
// import ModifySessionBooking from "./Component/ModifySessionBooking";
// import PreviewEvent from "./Component/PreviewEvent";
// import SessionBooking from "./Component/SessionBooking";
// import VirtualEventIframe from "./Component/VirtualEventIframe";
// import Booking from "./Pages/Booking";
// import Claim_profile from "./Pages/Claim_profile";
// import Events from "./Pages/Events";
// import MyPages from "./Pages/MyPages";
// import MySchedule from "./Pages/MySchedule";
// import Code_Discount from "./Pages/RefDiscount/Code_Discount";
// import Save from "./Pages/Save";
// import Schedule from "./Pages/Schedule";
// import UpcomingEvent from "./Pages/UpcomingEvent";
// import ViewEvent from "./Pages/ViewEvent";
// import Wallet from "./Pages/Wallet";
// import BankVerification from "./Pages/BankVerification";

const ForgetPassword = lazy(() => import("./Pages/ForgetPassword"));
const Home = lazy(() => import("./Pages/Home"));
const Login = lazy(() => import("./Pages/Login"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));
const ProviderDetailsPage = lazy(() => import("./Pages/ProviderDetailsPage"));
const TermsAndCondition = lazy(() => import("./Pages/TermsAndCondition"));
const UserProfile = lazy(() => import("./Pages/UserProfile"));
const ComingSoon = lazy(() => import("./Component/ComingSoon"));
const CreateEvent = lazy(() => import("./Component/CreateEvent/CreateEvent"));
const EventBooking = lazy(() => import("./Component/EventBooking"));
const EventInfo = lazy(() => import("./Component/EventInfo/EventInfo"));
const RecurrentEventsList = lazy(() => import("./Component/CreateEvent/RecurrentEventsList"));
const ModifyEventBooking = lazy(() => import("./Component/ModifyEventBooking"));
const ModifySessionBooking = lazy(() =>
  import("./Component/ModifySessionBooking")
);

const HelpAndSupport = lazy(() => import("./Pages/HelpAndSupport"));
const PreviewEvent = lazy(() => import("./Component/PreviewEvent"));
const SessionBooking = lazy(() => import("./Component/SessionBooking"));
const VirtualEventIframe = lazy(() => import("./Component/VirtualEventIframe"));
const Booking = lazy(() => import("./Pages/Booking"));
const Claim_profile = lazy(() => import("./Pages/Claim_profile"));
const Events = lazy(() => import("./Pages/Events"));
const MyPages = lazy(() => import("./Pages/MyPages"));
const MySchedule = lazy(() => import("./Pages/MySchedule"));
const Code_Discount = lazy(() => import("./Pages/RefDiscount/Code_Discount"));
const Save = lazy(() => import("./Pages/Save"));
const Schedule = lazy(() => import("./Pages/Schedule"));
const UpcomingEvent = lazy(() => import("./Pages/UpcomingEvent"));
const ViewEvent = lazy(() => import("./Pages/ViewEvent"));
const Wallet = lazy(() => import("./Pages/Wallet"));
const BankVerification = lazy(() => import("./Pages/BankVerification"));

export const socket = io(`${BASE_URL}${APP_VERSION}`, {
  autoConnect: false,
});

function App() {
  const momentZone = moment.tz.guess();
  const authDetails = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const eventData = JSON.parse(localStorage.getItem("eventData"));
  // for get virtual event code and redirect to virtual event
  const getCodeUrl = () => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    // const dublicateCode = "?code=4/0AfJohXmbSojow4mt5kCYEgdzreEQBX7L-JULyWzCfAnuj7eKcdWURkGb-xUJc9JYEx59Nw&scope=https://www.googleapis.com/auth/calendar.events"
    const dublicateCode = decodeURIComponent(code);
    if (code) {
      localStorage.setItem("EVENT_CODE", code);
      navigate(`${eventData?.url}?code=${dublicateCode}`);
      // Do something with the code
    }
  };

  useEffect(() => {
    setTimeout(() => {
      import("./Pages/ForgetPassword");
      import("./Pages/Home");
      import("./Pages/Login");
      import("./Pages/PrivacyPolicy");
      import("./Pages/ProviderDetailsPage");
      import("./Pages/TermsAndCondition");
      import("./Pages/UserProfile");
      import("./Component/ComingSoon");
      import("./Component/CreateEvent/CreateEvent");
      import("./Component/EventBooking");
      import("./Component/EventInfo/EventInfo");
      import("./Component/ModifyEventBooking");
      import("./Component/ModifySessionBooking");
      import("./Component/PreviewEvent");
      import("./Component/SessionBooking");
      import("./Component/VirtualEventIframe");
      import("./Pages/Booking");
      import("./Pages/Claim_profile");
      import("./Pages/Events");
      import("./Pages/MyPages");
      import("./Pages/MySchedule");
      import("./Pages/RefDiscount/Code_Discount");
      import("./Pages/Save");
      import("./Pages/Schedule");
      import("./Pages/UpcomingEvent");
      import("./Pages/ViewEvent");
      import("./Pages/Wallet");
      import("./Pages/BankVerification");
    }, 1000);
  }, []);

  useEffect(() => {
    getCodeUrl();
  }, [location.search]);

  const [firstTime, setfirstTime] = useState(true);

  useEffect(() => {
    setfirstTime(false);

    if (authDetails?.token) {
      const redirect_guest = sessionStorage.getItem("redirect_guest");
      const redirect_path = sessionStorage.getItem("redirect_path");

      if (redirect_guest) {
        navigate(redirect_path);
        sessionStorage.removeItem("redirect_guest");
        sessionStorage.removeItem("redirect_path");
      } else {
        if (!firstTime) {
          navigate("/");
        }
      }
    }
  }, [authDetails]);

  return (
    <>
      <Suspense fallback={<SuspenceLoader />}>
        {authDetails !== null && authDetails?.token ? (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/my_page" element={<MyPages />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/helpandsupport" element={<HelpAndSupport />} />
            <Route path="/bank_verification" element={<BankVerification />} />
            <Route
              path="/book-session/:providerId"
              element={<SessionBooking />}
            />
            <Route
              path="/modify-book-session/:providerId/:bookingId"
              element={<ModifySessionBooking />}
            />
            <Route
              path="/book-session/:providerId/:serviceId"
              element={<SessionBooking />}
            />{" "}
            {/* ----- for share ------ */}
            <Route
              path="/upcoming-events/:providerId"
              element={<UpcomingEvent />}
            />
            <Route
              path="/view-events/:id/:providerId"
              element={<ViewEvent />}
            />
            <Route
              path="/view-events/:id/:providerId/:bookingId"
              element={<ViewEvent />}
            />
            <Route
              path="/view-events/:id/:providerId/:bookingId/:reviewOpen/:userId"
              element={<ViewEvent />}
            />
            <Route path="/preview-events/:id" element={<PreviewEvent />} />
            <Route
              path="/event-booking/:id/:packageId/:participants/:providerId"
              element={<EventBooking />}
            />{" "}
            {/*.....view booking....*/}
            <Route
              path="/event-modify/:id/:packageId/:participants/:providerId/:bookingId"
              element={<ModifyEventBooking />}
            />{" "}
            {/*.....Modify booking....*/}
            <Route path="/my_schedule" element={<Schedule />} />
            <Route path="/my_events" element={<Events />} />
            <Route path="/my_events/:id" element={<EventInfo />} />
            <Route path="/my_events/events" element={<RecurrentEventsList />} />
            <Route path="/edit-events/:id" element={<CreateEvent />} />{" "}
            {/* ..... for select edit event ....*/}
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms_and_condition"
              element={<TermsAndCondition />}
            />
            <Route
              path="/provider_details/:providerId"
              element={<ProviderDetailsPage />}
            />
            <Route
              path="/provider_details/:providerId/:serviceId"
              element={<ProviderDetailsPage />}
            />{" "}
            <Route
              path="/provider_details/:providerId/:serviceId/:reviewOpen/:userId"
              element={<ProviderDetailsPage />}
            />{" "}
            {/* ..... for select service ....*/}
            <Route path="/my-schedule/cominsoon" element={<MySchedule />} />
            <Route path="/save" element={<Save />} />
            <Route path="/create-event" element={<CreateEvent />} />{" "}
            {/* ..... for select create event ....*/}
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/comingSoon" element={<ComingSoon />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route
              path="/claim-provider/:id"
              element={<Claim_profile />}
            />{" "}
            {/*.....Claim profile....*/}
            <Route
              path="/VirtualEventIframe"
              element={<VirtualEventIframe />}
            />{" "}
            {/*.....Claim profile....*/}
            <Route path="/refer-discount" element={<Code_Discount />} />{" "}
            {/*.....Claim profile....*/}
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Home />} /> {/*.....home....*/}
            <Route path="/bank_verification" element={<BankVerification />} />
            <Route path="/login" element={<Login />} />
            <Route path="/comingSoon" element={<ComingSoon />} />
            <Route path="/forgot_password" element={<ForgetPassword />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms_and_condition"
              element={<TermsAndCondition />}
            />
            <Route
              path="/provider_details/:providerId"
              element={<ProviderDetailsPage />}
            />{" "}
            <Route
              path="/provider_details/:providerId/:serviceId/:reviewOpen/:userId"
              element={<ProviderDetailsPage />}
            />{" "}
            {/*.....details....*/}
            <Route
              path="/book-session/:providerId"
              element={<SessionBooking />}
            />{" "}
            {/*.....booking....*/}
            <Route
              path="/book-session/:providerId/:serviceId"
              element={<SessionBooking />}
            />{" "}
            {/*.....booking....*/}
            <Route
              path="/upcoming-events/:providerId"
              element={<UpcomingEvent />}
            />{" "}
            {/*.....upcoming....*/}
            <Route
              path="/view-events/:id/:providerId"
              element={<ViewEvent />}
            />
            <Route
              path="/view-events/:id/:providerId/:bookingId/:reviewOpen/:userId"
              element={<ViewEvent />}
            />
            {/*.....view event....*/}
            <Route
              path="/event-booking/:id/:packageId/:participants/:providerId"
              element={<EventBooking />}
            />{" "}
            {/*.....view booking....*/}
            <Route
              path="/claim-provider/:id"
              element={<Claim_profile />}
            />{" "}
            {/*.....Claim profile....*/}
            <Route
              path="/provider_details/:providerId/:serviceId"
              element={<ProviderDetailsPage />}
            />{" "}
            {/* ..... for select service ....*/}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        style={{ zIndex: 1000000 }}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
      {/* <UserProfile /> */}
    </>
  );
}

export default App;
