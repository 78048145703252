import {event} from "../action/constant";

const initialState = {event: [], bookedEvent:[], removeEvent: [], modifyedEvent: [], checkModifyPayment: [], acceptedEventInvitaion: [], answerForm : false, deviceToken : ""};

export default (state = initialState, action) => {
    switch (action.type) {
        case event?.GET_EVENTS:
            return {...state, event: action.payload};
        case event?.CHECK_MODIFY_PAYMENT:
            return {...state, checkModifyPayment: action.payload};
        case event?.BOOK_EVENT:
            return {...state, bookedEvent: action.payload};
        case event?.MODIFY_EVENT:
            return {...state, modifyedEvent: action.payload};
        case event?.REMOVE_MODIFY_EVENT:
            return {...state, modifyedEvent: action.payload};
        case event?.REMOVE_EVENT:
            return {...state, bookedEvent: action.payload};
        case event?.REMOVE_CHECK_MODIFY_PAYMENT:
            return {...state, checkModifyPayment: action.payload};
        case event?.ACCEPT_EVENT_NOTIFICATION:
            return {...state, acceptedEventInvitaion: action.payload};
        case event?.ANSWER_FORM:
            return {...state, answerForm: action.payload};
        case event?.DEVICE_TOKEN:
            return {...state, deviceToken: action.payload};

    }
    return state;
};
